@use '~/src/sass/abstracts/utils' as *;
@use '~/src/sass/abstracts/variables/colors';
@use '~/src/sass/abstracts/variables/structure';

.hero-slider-video {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	overflow: hidden;
	font-weight: 900;
	border-radius: 20px;

	&::before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		content: '';
		background-color: colors.$grey-400;
		border-radius: 20px;
		opacity: 0.3;
	}
}

.hero-slider-video .hero-slider-video__video,
.hero-slider-video iframe {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 20px;
}

.hero-slider-video iframe {
	width: 100%;
	height: 100%;
}

.hero-slider-video__logo {
	position: absolute;
	top: 1.7rem;
	left: 2.6rem;
	width: 11rem;
}

.hero-slider-video__wrapper {
	z-index: 1;
	display: flex;
	align-items: flex-end;
	height: 100%;
	padding: 0 3.2rem 6rem;
	color: colors.$white;
	text-transform: uppercase;
}

.hero-slider-video-swiper {
	width: 100%;
	height: auto;
}

.hero-slider-video-slide {
	height: auto;
}

.hero-slider-video-slide__content {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.hero-slider-video-slide-header {
	display: flex;
	column-gap: 1rem;
	align-items: flex-end;
	margin-bottom: 3rem;
}

.hero-slider-video-slide-header .hero-slider-video-slide-header__number {
	margin: 0;

	@extend %font-sm;

	font-weight: 900;
	line-height: 0.8;
}

.hero-slider-video-slide-header__progress-bar-wrapper {
	width: 100%;
	height: 2px;
	background: colors.$grey-400;
}

.swiper-slide-active .hero-slider-video-slide-header__progress-bar {
	height: 2px;
	background-color: colors.$white;
	animation: progress-bar-animation 4s;
}

@keyframes progress-bar-animation {
	from {
		width: 0;
	}

	to {
		width: 100%;
	}
}

.hero-slider-video-slide-body {
	color: currentcolor;
	text-decoration: none;

	&:visited,
	&:hover,
	&:focus,
	&:active {
		color: colors.$white;
	}
}

.hero-slider-video-slide-body__title {
	@extend %font-md;
}

.hero-slider-video-scroll {
	position: relative;
	bottom: 0;
	left: calc(2rem + 2px + (100% - (2rem * 2) - (2px * 5)) / 4);
	display: flex;
	column-gap: 1.2rem;
	width: fit-content;
	padding-bottom: 2rem;
	padding-left: 1.2rem;
	border-left: 2px solid colors.$white;
}

.hero-slider-video-scroll__icons {
	display: flex;
	flex-direction: column;
	row-gap: 1.7rem;
	align-items: center;
	color: colors.$white;
}

.hero-slider-video-scroll__mouse {
	font-size: 3.3rem;
}

.hero-slider-video-scroll__arrow {
	font-size: 2.3rem;
	animation: arrow-bounce 2s infinite;
}

@keyframes arrow-bounce {
	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translateY(0);
	}

	40% {
		transform: translateY(-10px);
	}

	60% {
		transform: translateY(-5px);
	}
}

.hero-slider-video-scroll__text {
	display: block;
	width: 11rem;

	@extend %font-xs;

	font-weight: 900;
	color: colors.$white;
	text-transform: uppercase;
}

@media screen and (max-width: structure.$tablet-landscape - 1) {
	.hero-slider-video {
		min-height: calc(100vh - structure.$header-height - 3rem);
	}
}

@media screen and (min-width: structure.$tablet-landscape) {
	.hero-slider-video .hero-slider-video__video,
	.hero-slider-video iframe {
		width: 100%;
	}

	.hero-slider-video__logo {
		top: 3rem;
		left: 4.7rem;
		width: 17.8rem;
	}

	.hero-slider-video__wrapper {
		padding: 11rem 3.5rem 9.4rem;
	}

	.hero-slider-video-slide {
		flex: 0 0 50%;
		height: 20rem; // Fixes the slider translation.
		transition: flex 600ms ease-in-out;
	}

	.hero-slider-video-slide__content {
		width: 100%;
	}

	.hero-slider-video-slide.swiper-slide-next,
	.swiper-slide-next
		+ .hero-slider-video-slide.swiper-slide-duplicate-prev {
		flex: 0 0 25%;
		transition: unset;
	}

	.hero-slider-video-swiper--light .hero-slider-video-slide:nth-child(1) {
		flex: 0 0 calc(75% - (8rem / 2));
		margin-right: 8rem;
	}

	.hero-slider-video-swiper--light .hero-slider-video-slide:nth-child(2) {
		flex: 0 0 calc(25% - (8rem / 2));
	}

	.hero-slider-video-slide-body {
		transition: transform 300ms ease-in-out;
		transform: scale(0.6);
		transform-origin: left top;
	}

	.swiper-slide-active .hero-slider-video-slide-body {
		transform: scale(1);
	}

	.hero-slider-video-slide-body__title {
		font-size: 2.8rem;

		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}

	.hero-slider-video-scroll {
		left: calc(3.5rem + 2px + (100% - (3.5rem * 2) - (2px * 6)) / 5);
	}
}
